<template>
  <div class="w-100">
    <div class="col-lg-6 mx-auto">
      <!-- <div class="text-end d-flex justify-content-end">
        <a
          href="https://nyxex.com"
          target="_blank"
          class="nx-card__close d-flex align-items-center justify-content-center"
        >
          <i class="bi bi-x-lg"></i>
        </a>
      </div> -->
      <div class="mx-auto nx-card w-100">
        <slot></slot>
      </div>
    </div>
    <div class="mt-4 text-center">
      <div class="small nx-text--dark">
        <span class="opacity-50">Learn more about NYXEX Token Presale at </span>
        <a
          href="https://www.nyxex.com/"
          target="_blank"
          class="nx-text--primary"
          >https://www.nyxex.com/</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardWrapper",
};
</script>

<style>
.nx-card {
  min-height: 300px;
  background: var(--nx-white);
  width: 100%;
  border-radius: 16px;
  /* border-top-right-radius: 0; */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  padding: 2.2rem 1.5rem;
}
.nx-card__close {
  font-size: 1rem;
  color: var(--nx-dark);
  display: inline-flex;
  height: 40px;
  width: 40px;
  background: var(--nx-white);
}
@media screen and (max-width: 992px) {
  .nx-card {
    padding: 1rem;
  }
}
</style>
