export const shortenCryptoPrice = (balance) => {
  const numericBalance = Number(balance);
  if (!isNaN(numericBalance)) {
    let formattedBalance = numericBalance.toFixed(10);
    formattedBalance = formattedBalance.replace(/\.?0*$/, "");
    if (formattedBalance.includes(".")) {
      const [integerPart, decimalPart] = formattedBalance.split(".");
      formattedBalance = integerPart + "." + decimalPart.padEnd(2, "0");
    } else {
      formattedBalance += ".00";
    }
    return formattedBalance;
  } else {
    return "0";
  }
};
