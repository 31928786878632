<template>
  <div class="container d-flex align-items-start justify-content-center py-5">
    <card-wrapper>
      <CoinList v-if="!coinSelected" @coinSelected="handleCoinSelected" />
      <PurchaseInit
        v-if="coinSelected"
        ref="purchase_init"
        @startOver="restartProcess"
      />
    </card-wrapper>
  </div>
</template>

<script>
import CardWrapper from "@/components/CardWrapper.vue";
import CoinList from "@/components/CoinList.vue";
import PurchaseInit from "@/components/Purchase/PurchaseInit.vue";
export default {
  name: "HomeView",
  components: {
    CardWrapper,
    CoinList,
    PurchaseInit,
  },
  data() {
    return {
      coinSelected: false,
    };
  },
  methods: {
    restartProcess() {
      this.coinSelected = false;
    },
    handleCoinSelected(item) {
      this.coinSelected = true;
      this.$nextTick(() => {
        this.$refs.purchase_init.startPayment(item);
      });
    },
  },
};
</script>
