import { defaultWagmiConfig } from "@web3modal/wagmi/vue";
import { mainnet, bsc, polygon, sepolia } from "@wagmi/core/chains";
import { getAccount, getClient, switchChain } from "@wagmi/core";
import { useWeb3Modal, createWeb3Modal } from "@web3modal/wagmi/vue";
import { ethers } from "ethers";
import { reactive } from "vue";
export const chains = [mainnet, bsc, polygon];
export const projectId = "d4076d74c85fcd767042e941cf1e2e84";

// import { ethers as eth } from "@wagmi/core/src/utils/";
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata: {
    name: "NYXEX Presale",
    description: "NYXEX Presale",
    url: "",
    icons: [],
    verifyUrl: "",
  },
  enableWalletConnect: true,
});

export const handleSwitchChain = async ({ chainId }) => {
  const account = getAccount(config);
  console.log({ chainId, acountChainId: account.chainId });
  if (account.status == "connected" && chainId) {
    await switchChain(config, { chainId });
  }
};

const modal1 = createWeb3Modal({
  wagmiConfig: config,
  projectId,
  chains,
  themeMode: "dark",
  excludeWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  ],
  themeVariables: {
    "--w3m-color-mix": "#1e65aa",
    "--w3m-color-mix-strength": 20,
  },
});
// getClient()
// modal1.
// console.log(modal1.getState())

/**
 * get provider
 * @returns
 */
// export const getProvider = async () =>
//   await getAccount().connector.getProvider();

/**
 * get signer
 * @returns
 */
export const getSigner = async (provider) =>
  new ethers.providers.Web3Provider(provider).getSigner();

/**
 * connection data
 */
export const connectionData = reactive({
  connected: false,
  account: null,
});

export const modal = useWeb3Modal();
// { provider, signer, modal } = useWeb3Modal();
