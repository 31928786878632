<template>
  <div>
    <button
      type="button"
      @click="modal.open()"
      class="nx-button"
      v-if="!connectionData.connected"
    >
      Connect Wallet
    </button>
    <button type="button" class="nx-button" v-else>Create Transaction</button>
  </div>
</template>

<script setup>
import { modal } from "@/helpers/wagmiConfig";
import { reactive, watch, onMounted } from "vue";
import { projectId, config, connectionData } from "@/helpers/wagmiConfig";
import {
  getAccount,
  watchChainId,
  watchAccount,
  watchConnections,
} from "@wagmi/core";
import {
  createWeb3Modal,
  useWeb3Modal,
  useWeb3ModalEvents,
} from "@web3modal/wagmi/vue";
const emit = defineEmits(["walletConnected", "walletDisconnected"]);
const events = useWeb3ModalEvents();
const handleWalletConnected = () => {
  connectionData.account = getAccount(config);
  connectionData.connected = true;
  emit("walletConnected", "Event payload");
};
const handleWalletDisconnected = () => {
  connectionData.connected = false;
  emit("walletConnected", "Event payload");
};
const unwatch = watchChainId(config, {
  onChange(chainId) {
    console.log("Chain ID changed!", chainId);
    handleWalletConnected();
  },
});

watchAccount(config, {
  onChange(data) {
    console.log("Account changed!", data);
    handleWalletConnected();
  },
});

watchConnections(config, {
  onChange(data) {
    console.log("Connections changed!", data);
  },
});

onMounted(() => {
  const account = getAccount(config);
  if (account) {
    connectionData.account = account;
    if (account.isConnected && account.status == "connected") {
      handleWalletConnected();
    }
  }
});
watch(
  events,
  (newValue, oldValue) => {
    const EVENT = newValue.data.event;
    switch (EVENT) {
      case "CONNECT_SUCCESS":
        return handleWalletConnected();
        break;
      case "DISCONNECT_SUCCESS":
        return handleWalletDisconnected();
        break;

      default:
        break;
    }
  },
  { deep: true }
);
watch(
  connectionData,
  (newValue, oldValue) => {
    const data = newValue.account;
    if (data && data.isConnected && data.status == "connected") {
      handleWalletConnected();
    }
  },
  { deep: true }
);
</script>
