<template>
  <div>
    <div class="d-flex justify-content-center">
      <img src="@/assets/logo.png" class="nx-brand-logo" alt="" />
    </div>
    <div class="text-center">
      <h1 class="nx-title nx-text--dark">Select Pair to Purchase</h1>
    </div>
    <Spinner v-if="isLoading" position="center" variant="layout" />
    <div
      class="my-3"
      v-if="!isLoading && coinList.length > 0 && !errorOccurred"
    >
      <div
        v-for="item in coinList"
        :key="item.pair"
        class="d-flex align-items-center justify-content-between my-4 nx-coin-item"
        @click="selectCoin(item)"
      >
        <div class="d-flex align-items-center">
          <div class="me-3">
            <img
              :src="item.logoUrl"
              class="nx-coin-logo nx-coin-logo--regular"
              crossorigin=""
              alt=""
            />
          </div>
          <div>
            <div class="nx-body nx-text--dark nx-text--medium">
              {{ item.pair }}
            </div>
            <div class="nx-body--small nx-text--dark">
              {{ shortenCryptoPrice(item.quantity) }} {{ item.currency }}
            </div>
          </div>
        </div>
        <span class="nx-title nx-text--dark opacity-25">
          <i class="bi bi-chevron-right"></i>
        </span>
      </div>
    </div>
    <div
      class="text-center py-5 nx-body nx-text--dark col-8 mx-auto"
      v-if="!isLoading && errorOccurred"
    >
      <p>
        An error occurred while fetching coins available for presale. If the
        issue persists please contact support
      </p>
      <a
        href="javascript:void(0)"
        class="text-info text-decoration-none"
        @click="getCoinList"
        >Retry</a
      >
    </div>
  </div>
</template>

<script>
import axiosService from "@/axiosService";
import Spinner from "@/components/Spinner.vue";
import { shortenCryptoPrice } from "@/helpers";
export default {
  name: "CoinList",
  components: {
    Spinner,
  },
  data() {
    return {
      coinList: [],
      isLoading: false,
      errorOccurred: false,
    };
  },
  created() {
    this.getCoinList();
  },
  methods: {
    shortenCryptoPrice,
    selectCoin(item) {
      this.$emit("coinSelected", item);
    },
    async getCoinList() {
      if (this.isLoading) return;
      this.isLoading = true;
      this.errorOccurred = false;
      try {
        const res = (await axiosService.get("/fetch-tokens")).data;
        this.coinList = res.data;
      } catch (error) {
        console.log(error);
        this.errorOccurred = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.nx-coin-item {
  padding: 1rem;
  border: 1px solid rgba(221, 221, 221, 0.2);
  cursor: pointer;
  transition: 0.5s ease-in-out;
  border-radius: 8px;
}
.nx-coin-item:hover {
  background: rgba(30, 101, 170, 0.15);
  box-shadow: 0 4px 90px rgba(0, 0, 0, 0.15);
}
</style>
