import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Toast from "vue-toastification";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.css";
import "vue-toastification/dist/index.css";
import "./style.css";
import { numeric } from "@/directives";
import Spinner from "@/components/Spinner";
const app = createApp(App);
app.use(Toast, {
  timeout: 8000,
  pauseOnFocusLoss: false,
  hideProgressBar: true,
  position: "top-center",
  transition: "Vue-Toastification__fade",
});
app.component("Spinner", Spinner);
app.directive("numeric", numeric);
app.use(router);
app.mount("#app");
