// directives.js
export const numeric = {
  mounted(el) {
    const numericOnlyRegex = /^([0-9]*[.]?[0-9]*)$/;
    el.addEventListener("input", function (event) {
      const currentValue = event.target.value;
      const numericValue = currentValue.match(numericOnlyRegex);
      const sanitizedValue = numericValue ? numericValue[0] : "";
      if (currentValue !== sanitizedValue) {
        event.target.value = sanitizedValue;
        event.target.dispatchEvent(new Event("input"));
      }
    });
    el.addEventListener("blur", function (event) {
      let currentValue = event.target.value;
      const numericValue = currentValue.match(numericOnlyRegex);
      let sanitizedValue = numericValue ? numericValue[0] : "";
      if (!sanitizedValue.trim()) {
        sanitizedValue = "";
      }
      if (currentValue !== sanitizedValue) {
        event.target.value = sanitizedValue;
        event.target.dispatchEvent(new Event("input"));
      }
    });
  },
};
