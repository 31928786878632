<template>
  <div>
    <PurchaseForm
      :activePair="activePair"
      v-if="activePair"
      @startOver="emitStartOver"
    />
  </div>
</template>

<script>
import PurchaseForm from "./PurchaseForm.vue";
export default {
  name: "PurchaseInit",
  components: {
    PurchaseForm,
  },
  data() {
    return {
      activePair: null,
      paymentStarted: false,
    };
  },
  methods: {
    startPayment(item) {
      this.activePair = item;
    },
    emitStartOver() {
      this.$emit("startOver");
    },
  },
};
</script>

<style></style>
