<template>
  <div>
    <div v-if="activePair && !showSummary">
      <div class="d-flex justify-content-center">
        <img
          :src="activePair.logoUrl"
          crossorigin=""
          class="nx-coin-logo nx-coin-logo--large"
          alt=""
        />
      </div>
      <div class="text-center mt-3 mb-5">
        <h1 class="nx-title nx-text--dark">Purchase {{ activePair.pair }}</h1>
        <div class="d-flex align-items-center justify-content-center">
          <w3m-account-button v-if="walletConnected" />
        </div>
      </div>
      <form @submit.prevent="handleSubmit">
        <div class="form-group mb-4">
          <div class="input-group nx-input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Enter Amount"
              v-model="payload.amount"
              inputmode="decimal"
              v-numeric
            />
          </div>
          <span
            class="text-danger small"
            v-if="payload.amount && v$.amount.$invalid"
            >Be sure to provide a valid amount</span
          >
        </div>
        <div
          class="form-group mb-4 text-end small nx-text--dark"
          v-if="estimatedValue > 0"
        >
          You get: ~ {{ estimatedValue }} NYX
        </div>
        <div class="form-group mb-4">
          <div class="input-group nx-input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Enter Wallet Address"
              v-model="payload.address"
            />
          </div>
          <span
            class="text-danger small"
            v-if="payload.address && v$.address.$invalid"
            >Be sure to provide a valid address</span
          >
        </div>
        <div class="mt-4 d-flex align-items-center justify-content-end">
          <button
            class="nx-button nx-button--outline me-2"
            type="button"
            :disabled="isLoading"
            @click="handleStartOver"
          >
            Cancel
          </button>

          <Web3Modal
            v-if="!walletConnected"
            @walletConnected="handleWalletConnected"
            @walletDisconnected="handleWalletDisconnected"
          />
          <button type="submit" class="nx-button" :disabled="isLoading" v-else>
            Create Transaction
            <Spinner v-if="isLoading" />
          </button>
        </div>
      </form>
    </div>
    <PurchaseSummary
      v-if="showSummary"
      :paymentSummary="paymentSummary"
      @startOver="handleStartOver"
      @goBack="handleGoBack"
    />
  </div>
</template>

<script>
import { reactive, ref, computed, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { useVuelidate } from "@vuelidate/core";
import { required, numeric, minLength } from "@vuelidate/validators";
import axiosService from "@/axiosService";
import PurchaseSummary from "./PurchaseSummary.vue";
import Web3Modal from "@/components/Web3Modal.vue";
import { mainnet, bsc, polygon } from "@wagmi/core/chains";
import { useSendTransaction, estimateGas } from "@wagmi/core";
import { parseGwei } from "viem";
import { abi } from "@/helpers/abi.js";
// import { usdtContractABI } from "@/config/data/abi.json";
import { MaticContractABI } from "@/config/data/matic.js";
import { handleSwitchChain } from "@/helpers/wagmiConfig";
// import { parseEther } from "viem"; // for parsing Ether values

import {
  sendTransaction,
  getAccount,
  getBalance,
  writeContract,
  prepareTransactionRequest,
  readContract,
  switchChain,
  waitForTransactionReceipt,
  watchAccount,
} from "@wagmi/core";

// import {
//   // createWeb3Modal,
//   useWeb3Modal,
//   // useWeb3ModalEvents,
// } from "@web3modal/wagmi/vue";

import { parseEther } from "viem";
import {
  config,
  modal,
  getSigner,
  // getProvider,
  connectionData,
} from "@/helpers/wagmiConfig";
import { ethers } from "ethers";

import Web3 from "web3";

const usdtContractABI = require("@/config/data/abi.json");
// const Web3 = require("web3");
// import { ethers } from "ethers";

// Minimal ERC20 ABI for transfer function
const minimalERC20ABI = [
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [],
    type: "function",
  },
];

export default {
  name: "PurchaseForm",
  components: {
    PurchaseSummary,
    Web3Modal,
  },
  props: {
    activePair: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    // const { data, isSuccess, isError, error } = useSendTransaction();

    // if (connectionData.account.)
    // console.log({ connectionData });
    // const { provider, signer, modal } = useWeb3Modal();
    // console.log(modal);
    let createdTransaction = {};
    const isLoading = ref(false);
    const walletConnected = ref(false);
    const showSummary = ref(false);
    const paymentSummary = ref(null);
    const toast = useToast();
    const isValidCryptoAddress = (address) => /^[a-zA-Z0-9]+$/.test(address);
    const payload = reactive({
      currency: props.activePair.currency,
      network: props.activePair.currency,
      contractAddress: props.activePair.contractAddress,
      network: props.activePair.network,
      recipientAddress: props.activePair.recipient,
      chainId: props.activePair.chainId,
      address: "",
      amount: "",
    });
    const validationRules = {
      network: { required },
      address: { required, minLength: minLength(26), isValidCryptoAddress },
      amount: { required, numeric },
    };
    let accountAddress = getAccount(config).address;
    const v$ = useVuelidate(validationRules, payload);
    const estimatedValue = computed(() => {
      const value = parseFloat(payload.amount / props.activePair.quantity);
      if (!isNaN(value)) {
        return value;
      } else {
        return 0;
      }
    });

    const unwatch = watchAccount(config, {
      onChange(data) {
        if (data.chainId != payload.chainId) {
          handleSwitchChain({ chainId: payload.chainId })
            .then((c) => c)
            .catch((e) => e);
          unwatch();
        }
        // console.log("---Account changed!----", data, props.activePair, payload);
        // handleWalletConnected();
      },
    });

    console.log({ connectionData, activePair: props.activePair });

    if (
      connectionData.account &&
      props.activePair &&
      connectionData.account?.chainId != props.activePair?.chainId
    ) {
      try {
        handleSwitchChain({ chainId: payload.chainId }).then((c) => c);

        console.log(`Done switching!`);
      } catch (error) {
        console.error(error);
        toast.error("An error occurred when attempting to switch chain!");
      }
    }

    function getTextMessage(message) {
      const regex1 = /([\s\S]+)(?=\n\nRequest Arguments:)/;
      const regex2 = /(?<=Details:\s)([\s\S]+)(?=\nVersion:)/;
      const match = regex1.exec(message);
      const match2 = regex2.exec(message);

      if (match2 && match2.length > 0) {
        return match2[0].trim();
      }
      if (match && match.length > 0) {
        return match[0].trim();
      }
      console.log({ match, message });
      return "Something broke, please refresh the page and try again.!";
    }

    async function sendToken(recipientAddress, amount, currency, config) {
      // try {
      props.activePair.amount = payload.amount;
      const isNotToken = ["ETH", "BNB", "MATIC"].includes(
        currency?.toUpperCase()
      );
      // console.log({ currency, isNotToken });
      // Get account address and provider
      const accountAddress = getAccount(config).address;
      const provider = await connectionData.account.connector.getProvider();
      const web3 = new Web3(provider);

      // Validate input data
      if (!recipientAddress || !amount || !currency) {
        throw new Error("Missing recipient address, amount, or currency");
      }

      // Get contract details based on currency
      const tokenAddress = {
        ETH: null, // ETH doesn't have a contract address
        BNB: "0xbb4CdB9CBd36B01819EdDDeBc4aCAa88C0aDbE6E",
        MATIC: "0x0000000000000000000000000000000000001010",
        USDT: "0xdac17f958d2ee523a2206206994597c13d831ec7",
        USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      }[currency.toUpperCase()];
      const abi = isNotToken ? null : usdtContractABI; // Use minimal ABI for ERC20 tokens

      // Check chain compatibility if applicable (assuming config provides chain data)
      if (
        tokenAddress &&
        config.chainId &&
        config.chainId !== connectionData.account.chainId
      ) {
        await switchChain(config, { chainId: props.activePair?.chainId });
        console.log(`Done switching!`);
      }

      // Get signer (not needed for ETH transfers)
      const signer = isNotToken ? null : await getSigner(provider);

      // Convert amount to wei (except for ETH)
      const amountWei = isNotToken
        ? ethers.utils.parseEther(amount)
        : ethers.utils.parseUnits(amount, 18);

      // Initialize contract instance (if applicable)
      const contract = isNotToken
        ? null
        : new web3.eth.Contract(usdtContractABI, tokenAddress);

      const flooredAmt = BigInt(
        Math.floor(
          Number(parseFloat(payload.amount).toFixed(2)) * 10 ** Number(6)
        )
      );

      // let iface = new ethers.utils.Interface(usdtContractABI);

      // const daiWithSigner = contract.connect(signer);

      // Get the ERC-20 contract instance
      // const erc20Contract = new web3.eth.Contract(
      //   usdtContractABI,
      //   tokenAddress
      // );

      // console.log({
      //   eth: " new web3.eth()",
      //   tokenAddress,
      //   methods: contract.methods.transfer,
      // });
      // const txData = contract.interface.encodeFunctionData("transfer", [
      //   payload.recipientAddress,
      //   flooredAmt,
      // ]);

      // Encode the transfer function call
      const txData = isNotToken
        ? "0x"
        : contract.methods
            .transfer(payload.recipientAddress, flooredAmt)
            .encodeABI();

      console.log({ txData });
      // const cr = new web3.eth;
      // console.log({ cr });
      // console.log(new web3().eth.Contract(abi, payload.contractAddress));
      // console.log({
      //   methods: new web3.eth.Contract(abi, payload.contractAddress).methods,
      // });

      const transactionData = await prepareTransactionRequest(config, {
        chainId: props.activePair?.chainId,
        data: txData,
        from: accountAddress,
        to: isNotToken ? recipientAddress : payload.contractAddress,
        value: isNotToken ? amountWei : "0",
      });

      console.log({
        transactionData,
        ok: ["BNB", "ETH", "MATIC"].includes(payload.currency?.toUpperCase())
          ? true
          : false,
      });
      // Send transaction
      const tx = await sendTransaction(config, transactionData);
      // ["BNB", "ETH", "MATIC"].includes(payload.currency?.toUpperCase())
      //   ? await sendTransaction(config, transactionData)
      //   : await writeContract(config, {
      //       abi: usdtContractABI,
      //       address: payload.contractAddress,
      //       functionName: "transfer",
      //       args: [payload.recipientAddress, flooredAmt],
      //     });

      // isNotToken
      //   ? await sendTransaction(config, transactionData)
      //   : await contract.transfer(recipientAddress, amountWei);

      // console.log({ tx });
      // Wait for transaction confirmation
      const transactionReceipt = waitForTransactionReceipt(config, {
        confirmations: 2,
        hash: tx,
      });

      const {
        blockHash,
        blockNumber,
        chainId,
        contractAddress,
        cumulativeGasUsed,
        effectiveGasPrice,
        from,
        gasUsed,
        logs,
        logsBloom,
        status,
        to,
        transactionHash,
        transactionIndex,
        type,
      } = await transactionReceipt;

      console.log({
        blockHash,
        blockNumber,
        chainId,
        contractAddress,
        cumulativeGasUsed,
        effectiveGasPrice,
        from,
        gasUsed,
        logs,
        logsBloom,
        status,
        to,
        transactionHash,
        transactionIndex,
        type,
      });
      paymentSummary.value = {
        ...paymentSummary.value,
        pair: props.activePair,
        confirmedDeposit: true,
      };

      console.log(`Transaction successful! Hash: ${transactionHash}`);

      const res = (
        await axiosService.put(
          `/confirm-transaction/${createdTransaction.address.transaction._id}`,
          {
            receipt: {
              decimals: ["BNB", "ETH", "MATIC"].includes(
                payload.currency?.toUpperCase()
              )
                ? 18
                : 6,
              blockHash,
              blockNumber: blockNumber.toString(),
              chainId,
              contractAddress,
              cumulativeGasUsed: cumulativeGasUsed.toString(),
              effectiveGasPrice: effectiveGasPrice.toString(),
              from,
              gasUsed: gasUsed.toString(),
              // logs,
              // logsBloom,
              status,
              to,
              transactionHash,
              transactionIndex,
              type,
            },
          }
        )
      ).data;
      console.log(res);

      paymentSummary.value = {
        ...paymentSummary.value,
        transactionHash,
      };

      paymentSummary.value = {
        ...paymentSummary.value,
        transferConfirmed: true,
      };

      return transactionReceipt;
      // } catch (error) {
      //   const message =
      //     error?.response?.data?.message ||
      //     error?.message ||
      //     "An error occurred. Please try again";
      //   console.error("Error sending transaction:", error);
      //   throw new Error(message); // Re-throw for handling in calling code
      // }
    }

    const sendUSDTTransaction = async (recipient, amount) => {
      try {
        return sendToken(
          payload.recipientAddress,
          payload.amount,
          payload.currency,
          config
        );
        accountAddress = getAccount(config).address;
        console.log({
          accountAddress,
          cchainid: connectionData.account.chainId,
          chainId2: payload.chainId,
        });
        if (connectionData.account.chainId != payload.chainId) {
          await handleSwitchChain({ chainId: payload.chainId });

          console.log(`Done switching!`);
        }
        connectionData.account = getAccount(config);

        console.log({ recipient, amount });
        const usdtContractAddress =
          "0xdAC17F958D2ee523a2206206994597C13D831ec7"; // USDT contract address

        const isMatic = payload?.currency?.toUpperCase() == "MATIC";
        const balanceConfig = {
          address: payload.address,
        };
        if (!["BNB", "ETH"].includes(payload.currency?.toUpperCase())) {
          balanceConfig.token = payload.contractAddress;
        }
        if (payload.currency?.toUpperCase() == "MATIC") {
          balanceConfig.token = "0x0000000000000000000000000000000000001010";
        }

        console.log({ balanceConfig });
        const balance = balanceConfig.token
          ? await readContract(config, {
              abi: usdtContractABI,
              address: balanceConfig.token,
              functionName: "balanceOf",
              args: [payload.address],
              account: accountAddress,
            })
          : await getBalance(config, balanceConfig);

        // console.log({
        //   balance,
        //   payload,
        //   balanceConfig,
        //   address: accountAddress,
        //   currency: payload.currency,
        //   balance2: await getBalance(config, {
        //     ...balanceConfig,
        //     token: "0x0000000000000000000000000000000000001010",
        //   }),
        // });
        if (Number(balance.formatted) < Number(payload.amount))
          throw new Error("Insufficient funds!");
        /*

            : await readContract(config, {
                abi: usdtContractABI,
                address: payload.contractAddress,
                functionName: "balanceOf",
                args: [payload.address],
                account: accountAddress,
              });
        console.log({ balance, balance2: Number(balance.toString()) });
        */

        // console.log("provider-----------------------------");

        const provider = await connectionData.account.connector.getProvider();
        // console.log(await connectionData.account.connector.getProvider());

        // console.log({ provider });

        // Initialize the USDT contract instance
        const usdtContract = new ethers.Contract(
          usdtContractAddress,
          usdtContractABI,
          await getSigner(provider)
        );
        // console.log(usdtContract.populateTransaction.)
        // Convert amount to wei
        const amountWei = ethers.utils.parseUnits(`${amount}`, 6); // USDT has 6 decimals

        // if (Number(balance.toString()) < Number(amount))
        //   throw new Error("Insufficient funds!");
        console.log({ amountWei });
        console.log({
          amountWei,
          amt: parseEther(`${payload.amount}`),
          amount,
          amt: payload.amount,
          amt2: Number(payload.amount) * 10 ** 6,
          am3: parseEther(`${Number(payload.amount)}`, 6),
        });

        const decimals = ["BNB", "ETH", "MATIC"].includes(
          payload.currency?.toUpperCase()
        )
          ? 18
          : await readContract(config, {
              abi: usdtContractABI,
              address: payload.contractAddress,
              functionName: "decimals",
              args: [],
              account: accountAddress,
            });

        console.log({
          // flooredAmt,
          recipientAddress: payload.recipientAddress,
          fm: ethers.utils.parseUnits(payload.amount, decimals),
          amt2: payload.amount,
        });

        const flooredAmt = BigInt(
          Math.floor(
            Number(parseFloat(payload.amount).toFixed(2)) *
              10 ** Number(decimals)
          )
        );
        // console.log({ hash: payload.contractAddress == "**" ? true : false });
        // console.log({ flooredAmt });
        const hash = ["BNB", "ETH"].includes(payload.currency?.toUpperCase())
          ? await sendTransaction(config, {
              to: payload.recipientAddress,
              value: parseEther(`${payload.amount}`),
            })
          : await writeContract(config, {
              abi: isMatic ? MaticContractABI : usdtContractABI,
              address: isMatic
                ? payload.recipientAddress
                : payload.contractAddress,
              functionName: isMatic ? "withdraw" : "transfer",
              args: isMatic
                ? [parseEther(`${payload.amount}`), payload.recipientAddress]
                : [payload.recipientAddress, flooredAmt],
            });

        const transactionReceipt = waitForTransactionReceipt(config, {
          confirmations: 2,
          hash,
        });
        const {
          blockHash,
          blockNumber,
          chainId,
          contractAddress,
          cumulativeGasUsed,
          effectiveGasPrice,
          from,
          gasUsed,
          logs,
          logsBloom,
          status,
          to,
          transactionHash,
          transactionIndex,
          type,
        } = await transactionReceipt;

        paymentSummary.value = {
          ...paymentSummary.value,
          confirmedDeposit: true,
        };

        // console.log({ transactionReceipt: await transactionReceipt });
        // console.log({
        //   receipt: {
        //     decimals: ["BNB", "ETH", "MATIC"].includes(payload.currency?.toUpperCase())
        //       ? 18
        //       : decimals,
        //     blockHash,
        //     blockNumber: blockNumber.toString(),
        //     chainId,
        //     contractAddress,
        //     cumulativeGasUsed: cumulativeGasUsed.toString(),
        //     effectiveGasPrice: effectiveGasPrice.toString(),
        //     from,
        //     gasUsed: gasUsed.toString(),
        //     // logs,
        //     // logsBloom,
        //     status,
        //     to,
        //     transactionHash,
        //     transactionIndex,
        //     type,
        //   },
        // }); //, receipt });
        const res = (
          await axiosService.put(
            `/confirm-transaction/${createdTransaction.address.transaction._id}`,
            {
              receipt: {
                decimals: ["BNB", "ETH", "MATIC"].includes(
                  payload.currency?.toUpperCase()
                )
                  ? 18
                  : decimals,
                blockHash,
                blockNumber: blockNumber.toString(),
                chainId,
                contractAddress,
                cumulativeGasUsed: cumulativeGasUsed.toString(),
                effectiveGasPrice: effectiveGasPrice.toString(),
                from,
                gasUsed: gasUsed.toString(),
                // logs,
                // logsBloom,
                status,
                to,
                transactionHash,
                transactionIndex,
                type,
              },
            }
          )
        ).data;
        console.log(res);

        paymentSummary.value = {
          ...paymentSummary.value,
          transactionHash: res?.verifiedTransactions,
        };

        paymentSummary.value = {
          ...paymentSummary.value,
          transferConfirmed: true,
        };

        toast.success("Transaction successful!");
        console.log("USDT transaction successful!");
      } catch (error) {
        showSummary.value = false;
        const message =
          error?.response?.data?.message ||
          error?.message ||
          "An error occurred. Please try again";
        if (message.includes("insufficient")) {
          toast.error("Insufficient Funds");
        } else {
          toast.error(getTextMessage(message));
        }
        // toast.error(error.response?.data?.message || error.message || error);
        console.error("Error sending USDT transaction:", error);
        // Handle error
      }
    };

    const handleSubmit = async () => {
      if (isLoading.value) return;
      isLoading.value = true;
      try {
        if (Number(payload.amount) <= 0) {
          throw new Error("Amount must be more than zero!");
        }
        props.activePair.amount = payload.amount;
        // this.$refs.payment_summary.walletDeposit();
        paymentSummary.value = {
          ...createdTransaction.address,
          pair: props.activePair,
          targetAddress: payload.address,
          estimatedValue,
        };

        showSummary.value = true;

        createdTransaction = (
          await axiosService.post("/create-transaction", payload)
        ).data;

        paymentSummary.value = {
          ...paymentSummary.value,
          transactionCreated: true,
        };

        // console.log(createdTransaction);
        const result = await sendUSDTTransaction(
          createdTransaction.address.depositAddress,
          parseEther(createdTransaction.address.amount)
        );

        // await sendTransaction(config, {
        //   to: res.address.depositAddress,
        //   value: parseEther(res.address.amount),
        // });
        // showSummary.value = true;
      } catch (error) {
        showSummary.value = false;
        const message =
          error?.response?.data?.message ||
          error?.message ||
          "An error occurred. Please try again";
        if (message.includes("insufficient")) {
          toast.error("Insufficient Funds");
        } else {
          toast.error(getTextMessage(message));
        }
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const handleStartOver = () => {
      emit("startOver");
    };
    const handleGoBack = () => {
      showSummary.value = false;
    };
    const handleWalletConnected = (data) => {
      console.log({ pair: props.activePair.chainId });
      walletConnected.value = true;

      if (getAccount(config)?.account?.chainId != props.activePair?.chainId) {
        handleSwitchChain({ chainId: props.activePair.chainId })
          .then((c) => c)
          .catch((e) => e);

        console.log(`Done switching!`);
      }
    };
    const handleWalletDisconnected = (data) => {
      walletConnected.value = false;
    };
    return {
      isLoading,
      payload,
      v$,
      showSummary,
      paymentSummary,
      estimatedValue,
      handleSubmit,
      handleStartOver,
      handleGoBack,
      walletConnected,
      handleWalletConnected,
      handleWalletDisconnected,
    };
  },
};
</script>

<style></style>
