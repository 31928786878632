<template>
  <div>
    <div>
      <div v-if="!paymentConfirmed">
        <div class="col-12 text-center mb-4">
          <div class="d-flex justify-content-center">
            <img src="@/assets/logo.png" class="nx-brand-logo" alt="" />
          </div>
          <div>
            <h1 class="nx-title nx-text--dark">Payment Confirmation</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 my-4">
            <div class="rounded-3 overflow-hidden">
              <span>
                Please wait for payment confirmation.<br /><br />

                Once the transaction is confirmed, you'll be able to copy the<br />

                transaction hash confirming the transfer of NYX tokens to the
                deposited wallet address.
              </span>
              <!-- <img :src="qrCodeUrl" class="d-block w-100 nx-qrcode rounded-3" alt="" /> -->
            </div>
            <div class="form-group mt-4">
              <div class="input-group border-primary">
                <input
                  type="text"
                  class="form-control"
                  :value="paymentSummary.transactionHash"
                  disabled
                  readonly
                />
                <span
                  class="input-group-text"
                  id="basic-addon2"
                  @click="copyToClipboard"
                >
                  <i :class="[copied ? 'bi bi-check2' : 'bi bi-copy']"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-xl-6 my-4">
            <h4 class="nx-body--large nx-text--dark mb-4">
              Transaction Details
            </h4>
            <div>
              <div
                class="d-flex align-items-center justify-content-between py-2 border-bottom border-secondary-subtle my-2"
              >
                <span class="nx-body nx-text--dark opacity-50">Pair</span>
                <span class="nx-body nx-text--dark">{{
                  paymentSummary?.pair?.pair || "N/A"
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between py-2 border-bottom border-secondary-subtle my-2"
              >
                <span class="nx-body nx-text--dark opacity-50">Amount</span>
                <span class="nx-body nx-text--dark"
                  >{{
                    shortenCryptoPrice(paymentSummary?.pair?.amount) || 0
                  }}
                  {{ paymentSummary?.pair?.currency }}</span
                >
              </div>
              <div
                class="d-flex align-items-center justify-content-between py-2 border-bottom border-secondary-subtle my-2"
              >
                <span class="nx-body nx-text--dark opacity-50"
                  >Amount (USD)</span
                >
                <span class="nx-body nx-text--dark"
                  >~{{ formatCurrency(priceInUSD) }}</span
                >
              </div>
              <div
                class="d-flex align-items-center justify-content-between py-2 border-bottom border-secondary-subtle my-2"
              >
                <span class="nx-body nx-text--dark opacity-50">You Get</span>
                <span class="nx-body nx-text--dark"
                  >~{{ shortenCryptoPrice(subTotal) }} NYX</span
                >
              </div>
            </div>
            <div>
              <div class="d-flex align-items-center w-100 my-2">
                <Spinner
                  isSmall
                  variant="layout"
                  v-if="!paymentSummary.transactionCreated"
                />
                <span
                  class="nx-body--large nx-text--dark text-success me-2"
                  v-else
                  ><i class="bi bi-check-circle-fill"></i
                ></span>
                <span
                  class="nx-body--small nx-text--dark"
                  :class="[!paymentSummary.transactionCreated && 'opacity-50']"
                  >{{
                    !paymentSummary.transactionCreated
                      ? "Initiating transaction."
                      : "Transaction has been created!"
                  }}</span
                >
              </div>
              <div class="d-flex align-items-center w-100 my-2">
                <Spinner
                  isSmall
                  variant="layout"
                  v-if="!paymentSummary.confirmedDeposit"
                />
                <span
                  class="nx-body--large nx-text--dark text-success me-2"
                  v-else
                  ><i class="bi bi-check-circle-fill"></i
                ></span>
                <span
                  class="nx-body--small nx-text--dark"
                  :class="[!paymentSummary.confirmedDeposit && 'opacity-50']"
                  >{{
                    !paymentSummary.confirmedDeposit
                      ? "Deposit is awaiting confirmation"
                      : "Deposit has been confirmed"
                  }}</span
                >
              </div>
              <div class="d-flex align-items-center w-100 my-2">
                <Spinner
                  isSmall
                  variant="layout"
                  v-if="!paymentSummary.transferConfirmed"
                />
                <span
                  class="nx-body--large nx-text--dark text-success me-2"
                  v-else
                  ><i class="bi bi-check-circle-fill"></i
                ></span>
                <span
                  class="nx-body--small nx-text--dark"
                  :class="[!paymentSummary.transferConfirmed && 'opacity-50']"
                >
                  {{
                    !paymentSummary.transferConfirmed
                      ? "NYX transfer confirmation is pending"
                      : "Transfer has been confirmed"
                  }}
                </span>
              </div>
            </div>
            <div class="d-flex justify-content-center py-3">
              <img
                :src="paymentSummary.pair.logoUrl"
                crossorigin=""
                class="nx-coin-logo nx-coin-logo--regular"
                alt=""
              />
              <div class="mx-2 nx-body pt-1 opacity-25">
                <i class="bi bi-arrow-right"></i>
              </div>
              <img
                src="@/assets/logo.png"
                crossorigin=""
                class="nx-coin-logo nx-coin-logo--regular"
                alt=""
              />
            </div>
            <!-- <div class="mt-5 d-flex justify-content-end align-items-center">
              <button
                class="nx-button nx-button--outline me-2"
                type="button"
                @click="goBack"
              >
                Go Back
              </button>
              <button class="nx-button" type="button" @click="confirmPayment">
                Confirm
              </button>
            </div> -->
          </div>
          <div class="col-6">
            <w3m-button />
          </div>
          <div class="col-6">
            <button
              class="nx-button nx-button--outline w-100"
              type="button"
              @click="goBack"
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="paymentConfirmed"
      class="d-flex flex-column align-items-center justify-content-center py-5"
    >
      <div style="max-width: 140px" class="mx-auto">
        <LottieAnimation
          :animation-data="require('@/assets/pending.json')"
          :auto-play="true"
          :loop="true"
          :speed="1"
          ref="anim"
        />
      </div>
      <div class="text-center col-lg-8 mx-auto mt-4">
        <h4 class="nx-body--large nx-text--dark">Transaction Initiated</h4>
        <p class="nx-body nx-text--dark opacity-50">
          Once deposit is confirmed to the presented address, the equivalent
          amount of NYX will be transfered to your address.
        </p>
      </div>
      <button @click="startOver" class="nx-button mt-5">
        Purchase Another
      </button>
    </div>
  </div>
</template>

<script>
import { LottieAnimation } from "lottie-web-vue";
import { shortenCryptoPrice } from "@/helpers";
import { io } from "socket.io-client";
import axios from "axios";
// import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/vue";
import { BrowserProvider, Contract, formatUnits } from "ethers";
import { useToast } from "vue-toastification";

const USDTAddress = "0xdac17f958d2ee523a2206206994597c13d831ec7";

// The ERC-20 Contract ABI, which is a common contract interface
// for tokens (this is the Human-Readable ABI format)
const USDTAbi = [
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function balanceOf(address) view returns (uint)",
  "function transfer(address to, uint amount)",
  "event Transfer(address indexed from, address indexed to, uint amount)",
];
// import { useWeb3Modal } from "@web3modal/ethers/vue";
// // 4. Use modal composable
// const modal = useWeb3Modal();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "d4076d74c85fcd767042e941cf1e2e84";

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};

// 3. Create your application's metadata object
const metadata = {
  name: "My Website",
  description: "My Website description",
  url: "https://mywebsite.com", // url must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

// 4. Create Ethers config
// const ethersConfig = defaultConfig({
//   /*Required*/
//   metadata,

//   /*Optional*/
//   enableEIP6963: true, // true by default
//   enableInjected: true, // true by default
//   enableCoinbase: true, // true by default
//   rpcUrl: "...", // used for the Coinbase SDK
//   defaultChainId: 1, // used for the Coinbase SDK
// });

// // 5. Create a Web3Modal instance
// const modal = createWeb3Modal({
//   ethersConfig,
//   chains: [mainnet],
//   projectId,
//   enableAnalytics: true, // Optional - defaults to your Cloud configuration
//   enableOnramp: true, // Optional - false as default
// });

export default {
  name: "PurchaseSummary",
  components: {
    LottieAnimation,
  },
  props: {
    paymentSummary: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      selectedNetwork: "BTC",
      recipientAddress: "",
      amount: 0,
      walletConnector: null, // WalletConnect instance

      copied: false,
      paymentConfirmed: false,
      priceInUSD: 0,
      socket: null,
      qrCodeUrl: "",
      confirmation: {
        walletDeposit: false,
        transferConfirmed: false,
      },
      toast: {},
    };
  },
  created() {
    this.toast = useToast();
    // modal.open()
    // const walletProvider = modal.getWalletProvider();
    // console.log({ walletProvider }, walletProvider);
    console.log({ sumary: this.paymentSummary });
    this.generateWalletConnectQR(
      this.paymentSummary.depositAddress,
      this.paymentSummary.network,
      this.paymentSummary.currency,
      this.paymentSummary.amount
    );
    this.computeUsdPrice();
    this.socket = io("https://presale-api.nyxexint.com");
    this.socket.on("connect", () => {
      console.log("Connected to server");
    });
    this.socket.on("error", () => {
      console.log("An error occurred");
    });
    /*
    this.socket.on("wallet-deposit", (data) => {
      if (
        data &&
        data.address?.toLowerCase() == this.paymentSummary.targetAddress?.toLowerCase()
      ) {
        this.confirmation.walletDeposit = true;
      }
    });
    this.socket.on("transfer-confirmed", (data) => {
      if (
        data &&
        data.address?.toLowerCase() == this.paymentSummary.targetAddress?.toLowerCase()
      ) {
        this.confirmation.transferConfirmed = !true;
      }
    });
    */
  },
  beforeDestroy() {
    this.closeSocket();
  },
  computed: {
    subTotal() {
      return parseFloat(
        this.paymentSummary.estimatedValue ||
          this.paymentSummary.amount / this.paymentSummary.pair?.quantity
      );
    },
  },
  methods: {
    shortenCryptoPrice,
    closeModal() {
      this.showModal = false;
    },
    walletDeposit() {
      this.confirmation.walletDeposit = true;
    },
    transferConfirmed() {
      this.confirmation.transferConfirmed = !true;
    },
    async connectWallet() {
      // Check if WalletConnect is already connected
      if (this.walletConnector && this.walletConnector.connected) {
        // If already connected, proceed with token transfer
        this.transferTokens();
        return;
      }

      // Initialize WalletConnect
      this.walletConnector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org", // Replace with your bridge URL
      });

      // Check if connection is already established
      if (!this.walletConnector.connected) {
        // Create a new session
        await this.walletConnector.createSession();
      }

      // Subscribe to session update events
      this.walletConnector.on("session_update", (error, payload) => {
        // Handle session update event
      });

      // Subscribe to disconnect events
      this.walletConnector.on("disconnect", (error, payload) => {
        // Handle disconnect event
      });

      // Display QR Code and await connection
      this.walletConnector.createSession().then(() => {
        const uri = this.walletConnector.uri;
        // Display QR Code using uri
        console.log("WalletConnect URI:", uri);
      });
    },
    async transferTokens() {
      const walletProvider = modal.getWalletProvider();

      async function getBalance() {
        if (!isConnected) throw Error("User disconnected");

        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        // The Contract object
        const USDTContract = new Contract(USDTAddress, USDTAbi, signer);
        const USDTBalance = await USDTContract.balanceOf(address);

        console.log(formatUnits(USDTBalance, 18));
      }

      /*
      const network = this.selectedNetwork;
      const token = network; // Assuming token symbol is the same as the network for simplicity
      const recipientAddress = this.recipientAddress;
      const amount = this.amount;

      // Request a transaction from WalletConnect
      const transaction = {
        to: recipientAddress,
        value: amount,
        currency: token,
      };

      try {
        // Send transaction request
        const result = await this.walletConnector.sendTransaction(transaction);
        console.log("Transaction result:", result);
        // Handle success
      } catch (error) {
        console.error("Transaction error:", error);
        // Handle error
      }
      */
    },

    generateWalletConnectQR(address, network, token, amount) {
      console.log("WALLET_CONNECT");
      const bridgeUrl = "https://bridge.walletconnect.org";
      const key = "CONNECT-KEY";
      let uri = `wc:${bridgeUrl}?uri=wc:${network}-ADDRESS@1?bridge=${bridgeUrl}&key=${key}&token=${token}`;

      if (amount) {
        uri += `&amount=${amount}`;
      }

      const encodedUri = encodeURIComponent(uri);
      const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${address}&size=200x200`;
      this.qrCodeUrl = qrCodeUrl;
      return qrCodeUrl;
    },
    closeSocket() {
      if (this.socket) {
        this.socket.disconnect();
      }
    },
    goBack() {
      this.$emit("goBack");
      this.closeSocket();
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    },
    replaceCurrency(currency) {
      let newVal;
      switch (currency) {
        case "USDT_TRON":
          newVal = "TRX";
          break;
        case "BSC":
          newVal = "BNB";
          break;
        case "USDT":
          newVal = "USDC";
          break;

        default:
          newVal = currency;
          break;
      }
      return newVal;
    },
    async computeUsdPrice() {
      console.log(this.paymentSummary.pair, "currency");
      if (this.paymentSummary.currency?.toUpperCase() === "USDT") {
        this.priceInUSD = 1 * this.paymentSummary?.pair?.amount || 1;
        return;
      }
      if (this.paymentSummary.currency?.toUpperCase() === "USDC") {
        this.priceInUSD = 1 * this.paymentSummary?.pair?.amount || 1;
        return;
      }
      try {
        const res = (
          await axios.get(
            `https://api.nyxexint.com/api/v1/binance/v3/ticker/24hr?symbol=${this.replaceCurrency(
              this.paymentSummary?.pair?.currency
            )}USDT`
          )
        ).data;
        console.log({
          res,
          amt: {
            lastPrice: Number(res?.lastPrice),
            n: Number(this.paymentSummary?.pair?.amount),
            m: this.paymentSummary?.pair?.amount,
          },
        });
        this.priceInUSD = res?.lastPrice
          ? parseFloat(
              Number(res?.lastPrice) * Number(this.paymentSummary?.pair?.amount)
            )
          : 0;
        console.log(this.priceInUSD);
      } catch (error) {
        console.log(error);
      }
    },
    startOver() {
      this.$emit("startOver");
    },
    confirmPayment() {
      this.paymentConfirmed = true;
    },
    generateQrCode(data, size = 250) {
      const baseURL = "https://chart.googleapis.com/chart";
      const encodedData = encodeURIComponent(data);
      const qrCodeURL = `${baseURL}?cht=qr&chs=${size}x${size}&chl=${encodedData}`;
      console.log(qrCodeURL);
      return qrCodeURL;
    },
    copyToClipboard() {
      if (this.copied) return;
      const textarea = document.createElement("textarea");
      textarea.value = this.paymentSummary.transactionHash;
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.copied = true;
      document.body.removeChild(textarea);
      setTimeout(() => {
        this.copied = false;
      }, 3000);
      this.toast.success("Transaction has been copied!");
    },
  },
  watch: {
    confirmation: {
      handler() {
        if (
          this.confirmation.walletDeposit &&
          this.confirmation.transferConfirmed
        ) {
          this.closeSocket();
          setTimeout(() => {
            this.paymentConfirmed = true;
          }, 3000);
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
